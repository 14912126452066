import { useColorStore } from 'src/zustand/useColorStore'

export const MessageFooter = () => {
    const { message } = useColorStore()
    
    return (
      <footer className="footer" style={{ height : '5vh' }}>
        <div className="footer__note">
          <div className="marquee">
            <span>{ message }</span>
          </div>
        </div>
      </footer>
    )
}
