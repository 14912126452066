import { create } from 'zustand'

type setColor = {
  (param1: string, param2: string, param3: string) : void
}

type backgroundColor = {
  tcolor: string,
  bcolor: string,
  message: string,
  setColor: setColor
}

export const useColorStore = create<backgroundColor>((set) => ({
  //  color: 'linear-gradient(151.49deg, #FAFF00 37.81%, #00BF7A 87.07%)',
  tcolor: '#101010',
  bcolor: 'linear-gradient(151.49deg, #FAFF00 37.81%, #00BF7A 87.07%)',
  message: '',
  setColor: (param1, param2, param3) => set(( state ) => ({ tcolor: param1, bcolor: param2, message: param3 })),
}))
