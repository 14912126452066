import { Util } from 'src/utils'
import { formatDate } from 'src/utils/formatDate'
import { Weather } from 'generated/backendSchemas'
import { format } from 'date-fns'
import rainyPercentIconPng from 'src/assets/images/weather-forecast/blackBoard/rainy_percent_icon.png'
import { useColorStore } from 'src/zustand/useColorStore'
import { MessageFooter } from 'src/components/parts/MessageFooter'

import './style.css'
import './vibration.css'
import './schedule.css'

export const ExamplePageDefault = ({ display_date, detail, weather, d_area_name, w_area_name, name }: Weather) => {
  const { tcolor, bcolor } = useColorStore()

  const background_colors = [
    '#005EAC',
    '#DF4747'
  ]
  
  const colors = [
    '#02C07A',
    '#02C07A',
    '#02C07A',
    '#02C07A',
    '#02C07A',
    '#02C07A',
    '#02C07A'
  ]

  colors.forEach((e, idx) => {
    if (weather) {
      if (weather[idx].day_of_week === '土') {
        colors[idx] = background_colors[0]
      }
      if (weather[idx].day_of_week === '日') {
        colors[idx] = background_colors[1]
      }
      if (weather[idx].is_holiday) {
        colors[idx] = background_colors[1]
      }
    }
  })

  let window_size = ''
  if (typeof window !== "undefined") {
    window_size = window.innerWidth.toString() + ':' + window.innerHeight.toString()
  }

  return (
    <div className="container schedule" style={{ overflow : 'hidden', background : bcolor}}>
      <div className="page-contents" style={{ height : '95vh' }}>
        <header className="header">
          <h1 className="header__title" style={{ fontSize : '5vh', color: tcolor }}>週間天気予報</h1>
          <div className="header__datetime">
            <div className="header__date">
                <span>{ format(new Date(), 'M') }</span>
              <span className="header__datetime-unit">月</span>
                <span>{ format(new Date(), 'd') }</span>
              <span className="header__datetime-unit">日</span>
            </div>
            <div className="header__time">
              <span className="header__datetime-unit header__time-unit">{ format(new Date(), 'a') }</span>
                <span>{ format(new Date(), 'HH:mm') }</span>
            </div>
          </div>
        </header>

        <main className="contents">
          <section className="schedule-content" style={{ height : '78vh' }}>
            <div style={{ height: '74vh', display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
              <div style={{ display : 'flex', justifyContent : 'space-between', color: 'white', fontSize: '3vh', fontWeight: 'bold', width: '100%', textAlign: 'left' }}>
                <div>{name}  （{d_area_name} / {w_area_name}）</div>
                <div>
                  <span style={{ color : 'white'}}>{ display_date && format(new Date(display_date), 'M') }</span>
                  <span style={{ color : 'white'}}>月</span>
                  <span style={{ color : 'white'}}>{ display_date && format(new Date(display_date), 'd') }</span>
                  <span style={{ color : 'white'}}>日</span>
                  <span style={{ color : 'white'}}>{ display_date && format(new Date(display_date), 'a') }</span>
                  <span style={{ color : 'white'}}>{ display_date && format(new Date(display_date), 'HH:mm') }</span>
                  <span style={{ color : 'white'}}>発表</span>
                </div>
              </div>

              <div style={{ width: '90vw', display: 'flex', justifyContent: 'center', gap: '1vw' }}>
                <div style={{ backgroundColor: 'white', height: '33vh', width: '29vw', display : 'flex' }}>
                  <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-around', backgroundColor: colors[0], height: '100%', width: '7vw', color: 'white', fontSize: '2.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div>{weather && weather[0] && weather[0].date && format(new Date(weather[0].date), 'M/d')}<br />（{weather && weather[0] && weather[0].day_of_week}）</div>
                  </div>
                  <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-around', height: '100%', width: '22vw', color: 'black', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div style={{ height : '50%', display : 'flex', borderBottom : '1px solid #888' }}>
                      <div style={{ width: '11vw', textAlign : 'center', display: 'flex', flexFlow: 'column', justifyContent: 'space-around' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>天気予報</div>
                        <div style={{ width: '100%' }}><img src={detail && detail.forecast_3d && detail.forecast_3d.day1.icon} style={{ width: '75%', margin : 'auto' }} /></div>
                        <div style={{ fontSize : '1vw'}}>{detail && detail.forecast_3d && detail.forecast_3d.day1.forecast}</div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : 'red' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最高気温</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day1.temps.max}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : 'blue' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最低気温</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day1.temps.min}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                    </div>
                    <div style={{ height: '40%', display: 'flex', borderBottom : '1px solid #888' }}>
                      <div style={{ width: '6vw', fontSize: '2.5vw', color: '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>00〜06</div>
                        {
                          (detail && detail.forecast_3d && detail.forecast_3d.day1.pops.T00) && (
                            <div>{detail && detail.forecast_3d && detail.forecast_3d.day1.pops.T00}<span style={{ fontSize: '1vw' }}>%</span></div>
                          )                        
                        }
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>06〜12</div>
                        {
                          (detail && detail.forecast_3d && detail.forecast_3d.day1.pops.T06) && (
                            <div>{detail && detail.forecast_3d && detail.forecast_3d.day1.pops.T06}<span style={{ fontSize : '1vw' }}>%</span></div>
                          )                        
                        }
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>12〜18</div>
                        {
                          (detail && detail.forecast_3d && detail.forecast_3d.day1.pops.T12) && (
                            <div>{detail && detail.forecast_3d && detail.forecast_3d.day1.pops.T12}<span style={{ fontSize : '1vw' }}>%</span></div>
                          )                        
                        }
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>18〜24</div>
                        {
                          (detail && detail.forecast_3d && detail.forecast_3d.day1.pops.T18) && (
                            <div>{detail && detail.forecast_3d && detail.forecast_3d.day1.pops.T18}<span style={{ fontSize : '1vw' }}>%</span></div>
                          )                        
                        }
                      </div>
                    </div>
                    <div style={{ height: '10%', fontSize : '1vw' }}>
                      {detail && detail.forecast_3d && detail.forecast_3d.day1.wind}
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: 'white', height: '33vh', width : '29vw', display : 'flex' }}>
                  <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-around', backgroundColor: colors[1], height: '100%', width: '7vw', color: 'white', fontSize: '2.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div>{weather && weather[1] && weather[1].date && format(new Date(weather[1].date), 'M/d')}<br />（{weather && weather[1] && weather[1].day_of_week}）</div>
                  </div>
                  <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-around', height: '100%', width: '22vw', color: 'black', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div style={{ height : '50%', display : 'flex', borderBottom : '1px solid #888' }}>
                      <div style={{ width: '11vw', textAlign : 'center', display: 'flex', flexFlow: 'column', justifyContent: 'space-around' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>天気予報</div>
                        <div style={{ width: '100%' }}><img src={detail && detail.forecast_3d && detail.forecast_3d.day2.icon} style={{ width: '75%', margin : 'auto' }} /></div>
                        <div style={{ fontSize : '1vw'}}>{detail && detail.forecast_3d && detail.forecast_3d.day2.forecast}</div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : 'red' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最高気温</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day2.temps.max}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : 'blue' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最低気温</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day2.temps.min}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                    </div>
                    <div style={{ height: '40%', display: 'flex', borderBottom : '1px solid #888' }}>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>00〜06</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day2.pops.T00}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>06〜12</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day2.pops.T06}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>12〜18</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day2.pops.T12}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>18〜24</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day2.pops.T18}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                    </div>
                    <div style={{ height: '10%', fontSize : '1vw' }}>
                      {detail && detail.forecast_3d && detail.forecast_3d.day2.wind}
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: 'white', height: '33vh', width : '29vw', display : 'flex' }}>
                  <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-around', backgroundColor: colors[2], height: '100%', width: '7vw', color: 'white', fontSize: '2.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div>{weather && weather[2] && weather[2].date && format(new Date(weather[2].date), 'M/d')}<br />（{weather && weather[2] && weather[2].day_of_week}）</div>
                  </div>
                  <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-around', height: '100%', width: '22vw', color: 'black', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div style={{ height : '50%', display : 'flex', borderBottom : '1px solid #888' }}>
                      <div style={{ width: '11vw', textAlign : 'center', display: 'flex', flexFlow: 'column', justifyContent: 'space-around' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>天気予報</div>
                        <div style={{ width: '100%' }}><img src={detail && detail.forecast_3d && detail.forecast_3d.day3.icon} style={{ width: '75%', margin : 'auto' }} /></div>
                        <div style={{ fontSize : '1vw'}}>{detail && detail.forecast_3d && detail.forecast_3d.day3.forecast}</div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : 'red' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最高気温</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day3.temps.max}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : 'blue' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最低気温</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day3.temps.min}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                    </div>
                    <div style={{ height: '40%', display: 'flex', borderBottom : '1px solid #888' }}>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>00〜06</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day3.pops.T00}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>06〜12</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day3.pops.T06}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>12〜18</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day3.pops.T12}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                      <div style={{ width: '6vw', fontSize : '2.5vw', color : '#444' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>18〜24</div>
                        <div>{detail && detail.forecast_3d && detail.forecast_3d.day3.pops.T18}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                    </div>
                    <div style={{ height: '10%', fontSize : '1vw' }}>
                      {detail && detail.forecast_3d && detail.forecast_3d.day3.wind}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display : 'flex', width : '89vw', justifyContent : 'center', gap : '1vw'}}>
                <div style={{ backgroundColor: 'white', height: '33vh', width : '25%', display : 'flex' }}>
                  <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-around', backgroundColor: colors[3], height: '100%', width: '7vw', color: 'white', fontSize: '2.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div>{weather && weather[3] && weather[3].date && format(new Date(weather[3].date), 'M/d')}<br />（{weather && weather[3] && weather[3].day_of_week}）</div>
                  </div>
                  <div style={{ display: 'flex', flexFlow: 'column', height: '100%', width : '100%', color: 'black', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div style={{ width: '100%', textAlign : 'center', display: 'flex', flexFlow: 'column', justifyContent: 'space-around' }}>
                      <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>天気予報</div>
                      <div style={{ width: '100%' }}><img src={weather && weather[3] && weather[3].weather_mark} style={{ width: '75%', margin : 'auto' }} /></div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', display: 'flex' }}>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'red' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最高気温</div>
                        <div>{weather && weather[3] && weather[3].highest}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'blue' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最低気温</div>
                        <div>{weather && weather[3] && weather[3].lowest}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'black' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>降水確率</div>
                        <div>{weather && weather[3] && weather[3].precipitation_percentage}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: 'white', height: '33vh', width : '25%', display : 'flex' }}>
                  <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-around', backgroundColor: colors[4], height: '100%', width: '7vw', color: 'white', fontSize: '2.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div>{weather && weather[4] && weather[4].date && format(new Date(weather[4].date), 'M/d')}<br />（{weather && weather[4] && weather[4].day_of_week}）</div>
                  </div>
                  <div style={{ display: 'flex', flexFlow: 'column', height: '100%', width : '100%', color: 'black', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div style={{ width: '100%', textAlign : 'center', display: 'flex', flexFlow: 'column', justifyContent: 'space-around' }}>
                      <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>天気予報</div>
                      <div style={{ width: '100%' }}><img src={weather && weather[4] && weather[4].weather_mark} style={{ width: '75%', margin : 'auto' }} /></div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', display: 'flex' }}>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'red' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最高気温</div>
                        <div>{weather && weather[4] && weather[4].highest}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'blue' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最低気温</div>
                        <div>{weather && weather[4] && weather[4].lowest}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'black' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>降水確率</div>
                        <div>{weather && weather[4] && weather[4].precipitation_percentage}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: 'white', height: '33vh', width : '25%', display : 'flex' }}>
                  <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-around', backgroundColor: colors[5], height: '100%', width: '7vw', color: 'white', fontSize: '2.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div>{weather && weather[5] && weather[5].date && format(new Date(weather[5].date), 'M/d')}<br />（{weather && weather[5] && weather[5].day_of_week}）</div>
                  </div>
                  <div style={{ display: 'flex', flexFlow: 'column', height: '100%', width : '100%', color: 'black', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div style={{ width: '100%', textAlign : 'center', display: 'flex', flexFlow: 'column', justifyContent: 'space-around' }}>
                      <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>天気予報</div>
                      <div style={{ width: '100%' }}><img src={weather && weather[5] && weather[5].weather_mark} style={{ width: '75%', margin : 'auto' }} /></div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', display: 'flex' }}>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'red' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最高気温</div>
                        <div>{weather && weather[5] && weather[5].highest}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'blue' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最低気温</div>
                        <div>{weather && weather[5] && weather[5].lowest}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'black' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>降水確率</div>
                        <div>{weather && weather[5] && weather[5].precipitation_percentage}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: 'white', height: '33vh', width : '25%', display : 'flex' }}>
                  <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-around', backgroundColor: colors[6], height: '100%', width: '7vw', color: 'white', fontSize: '2.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div>{weather && weather[6] && weather[6].date && format(new Date(weather[6].date), 'M/d')}<br />（{weather && weather[6] && weather[6].day_of_week}）</div>
                  </div>
                  <div style={{ display: 'flex', flexFlow: 'column', height: '100%', width : '100%', color: 'black', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>
                    <div style={{ width: '100%', textAlign : 'center', display: 'flex', flexFlow: 'column', justifyContent: 'space-around' }}>
                      <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>天気予報</div>
                      <div style={{ width: '100%' }}><img src={weather && weather[6] && weather[6].weather_mark} style={{ width: '75%', margin : 'auto' }} /></div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', display: 'flex' }}>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'red' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最高気温</div>
                        <div>{weather && weather[6] && weather[6].highest}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'blue' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>最低気温</div>
                        <div>{weather && weather[6] && weather[6].lowest}<span style={{ fontSize : '1vw' }}>℃</span></div>
                      </div>
                      <div style={{ width: '33%', fontSize : '2.5vw', color : 'black' }}>
                        <div style={{ fontSize : '1vw', width : '100%', backgroundColor : '#EEE'}}>降水確率</div>
                        <div>{weather && weather[6] && weather[6].precipitation_percentage}<span style={{ fontSize : '1vw' }}>%</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <MessageFooter />
    </div>
  )
}
